
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const icon_png_1 = require("../images/icon.png");

const IconCard = styled_components_1.default.div `
  min-width: 100px;
  max-width: 160px;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25));
`;
const AppIconImage = styled_components_1.default.img `
  width: 100%;
  height: auto;
  border-radius: 20px;
`;
function AppIcon() {
    return ((0, jsx_runtime_1.jsx)(IconCard, { children: (0, jsx_runtime_1.jsx)(AppIconImage, { src: icon_png_1.default, alt: "app-icon" }, void 0) }, void 0));
}
exports.default = AppIcon;
// # sourceMappingURL=appIcon.js.map