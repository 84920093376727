
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const bg_svg_1 = require("../images/bg.svg");

const LogoCardBackgroundContainer = styled_components_1.default.div `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  // background-image: url(${bg_svg_1.default});
  // background-size: cover;
  // background-repeat: no-repeat;
`;
function LogoCardBackground() {
    return ((0, jsx_runtime_1.jsx)(LogoCardBackgroundContainer, { children: (0, jsx_runtime_1.jsxs)("svg", {display: "block", width: "100%", height: "100%", viewBox: "50 0 300 520", fill: "none", xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", children: [(0, jsx_runtime_1.jsx)("path", { d: "M0 0.503802L196.844 0.503784C234.225 25.7209 239.096 38.9927 248.044 49.5C256.991 60.0073 257.985 75.0932 257.985 152.106C257.985 229.118 312.167 236.164 297.473 269.651C282.779 303.138 287.544 336.219 271.5 372.5C255.456 408.781 202.079 504.809 196.844 520.504H0V0.503802Z", fill: "url(#paint0_linear_27:2)" }, void 0), (0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsxs)("linearGradient", {id: "paint0_linear_27:2", x1: "217.971", y1: "103.437", x2: "-118.263", y2: "150.571", gradientUnits: "userSpaceOnUse", children: [(0, jsx_runtime_1.jsx)("stop", { stopColor: "#EAFFFD" }, void 0), (0, jsx_runtime_1.jsx)("stop", { offset: "1", stopColor: "#BFD6E3" }, void 0)]}, void 0) }, void 0)]}, void 0) }, void 0));
}
exports.default = LogoCardBackground;
// # sourceMappingURL=logoCardBackground.js.map