
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");

const TitleContainer = styled_components_1.default.div `
  font-family: "Raleway";
  font-weight: 800;
  font-size: 4rem;
`;
const FirstLetter = styled_components_1.default.span `
  color: #79b2bc;
  opacity: 100%;
`;
const RestLetters = styled_components_1.default.span `
  color: #79b2bc;
  opacity: 65%;
`;
const DescriptionContainer = styled_components_1.default.div `
  font-family: "IBM Plex Mono";
  font-weight: 200;
  font-size: 1.4rem;
  padding-top: 10%;
`;
const LinkContainer = styled_components_1.default.div `
  font-family: "IBM Plex Mono";
  font-weight: 200;
  font-size: 1rem;
  padding-top: 10%;
  color: #79b2bc;
  text-underline: #79b2bc;
  text-decoration-line: underline;
  text-decoration-style: dashed;
`;
function DownloadIntro() {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Prospective Electronic Polygenic Risk Study".split(" ").map((t) => ((0, jsx_runtime_1.jsxs)(TitleContainer, { children: [(0, jsx_runtime_1.jsx)(FirstLetter, { children: t[0] }, void 0), (0, jsx_runtime_1.jsx)(RestLetters, { children: t.slice(1) }, void 0)] }, t))), (0, jsx_runtime_1.jsx)(DescriptionContainer, { children: "The purpose of this study is to evaluate how knowing your genetic risk influences health decisions you make." }, void 0), (0, jsx_runtime_1.jsx)(DescriptionContainer, { children: "Participation in this study is by invitation only." }, void 0), (0, jsx_runtime_1.jsx)(LinkContainer, { children: "Not invited but still interested?" }, void 0)] }, void 0));
}
exports.default = DownloadIntro;
// # sourceMappingURL=downloadIntro.js.map