
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Optum_logo_svg_1 = require("../../images/Optum_logo.svg");
const logoContainer_1 = require("./logoContainer");

function OptumLogo() {
    return ((0, jsx_runtime_1.jsx)(logoContainer_1.default, { imageSrc: Optum_logo_svg_1.default }, void 0));
}
exports.default = OptumLogo;
// # sourceMappingURL=optumLogo.js.map