
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const linkage_svg_1 = require("../images/linkage.svg");

const LinkageContainer = styled_components_1.default.div `
  flex: 1;
  width: 55%;
  height: 100%;
  transform: scaleY(${(props) => props.scaleFactor});
`;
const PseudoSvg = styled_components_1.default.svg `
  height: 100%;
  width: 100%;
`;
const PseudoEmbed = styled_components_1.default.embed `
  height: 100%;
  width: 100%;
`;
function Linkage({ scaleFactor = 1 }) {
    return ((0, jsx_runtime_1.jsx)(LinkageContainer, {scaleFactor, children: (0, jsx_runtime_1.jsx)(PseudoEmbed, { src: linkage_svg_1.default, type: "image/svg+xml" }, void 0)}, void 0));
}
exports.default = Linkage;
// # sourceMappingURL=linkage.js.map