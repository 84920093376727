import * as React from "react";
import styled from "styled-components";
import LogoCardBackground from "../components/logoCardBackground";
import AppIcon from "../components/appIcon";
import LogoGroup from "../components/logos/logoGroup";
import appStore from "../images/app-store.svg";
import playStore from "../images/play-store.png";
import DownloadIntro from "../components/downloadIntro";
import HeartBackground from "../components/heartBackground";
import Manifest from "../components/manifest";

const DownloadPageOuterContainer = styled.div`
  height: 100%;
  min-height: 600px;
  position: relative;
`;

const DownloadPageContainer = styled.div`
  min-height: 100%;
  padding-left: 2%;
  padding-right: 2%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
`;

const LogoGroupOuterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  //border-width: 1px;
  //border-style: solid;
  //border-color: red;
`;

const AppIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60%;
  width: 100%;
  //border-width: 1px;
  //border-style: solid;
  //border-color: red;
`;

const AppIconTray = styled.div`
  padding: 20px;
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
  background-color: black;
  opacity: 1;
`;

const StoreBadgeGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 150px;
  //height: 60%;
  //width: 100%;
`;

const BadgeContainer = styled.div`
  width: 60%;
  //height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const TextBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  //height: 80%;
  width: 100%;
`;

const HeartBackgroundContainer = styled.div`
  position: absolute;
  top: 20%;
  bottom: 0;
  left: 60%;
  right: 0;
  overflow: hidden;
`;

function Download() {
  return (
      <>
        <Manifest />
        <DownloadPageOuterContainer>
          <LogoCardBackground />
          <HeartBackgroundContainer id="heart-background">
            <HeartBackground />
          </HeartBackgroundContainer>
          <DownloadPageContainer>
            <LogoGroupOuterContainer id="logo-group-container">
              <LogoGroup />
            </LogoGroupOuterContainer>

            <AppIconContainer id="app-icon-container">
              <AppIcon />
            </AppIconContainer>

            <StoreBadgeGroupContainer>
              <BadgeContainer>
                <img
                    width="88%"
                    // height="88%"
                    src={appStore}
                    alt="app-store-badge"
                />
              </BadgeContainer>
              <BadgeContainer>
                <img width="100%" src={playStore} alt="app-store-badge" />
              </BadgeContainer>
            </StoreBadgeGroupContainer>

            <TextBodyContainer>
              <DownloadIntro />
            </TextBodyContainer>
          </DownloadPageContainer>
        </DownloadPageOuterContainer>
      </>

  );
}

export default Download;
