
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const srti_stacked_png_1 = require("../../images/srti-stacked.png");
const logoContainer_1 = require("./logoContainer");

function SRTILogo() {
    return (0, jsx_runtime_1.jsx)(logoContainer_1.default, { imageSrc: srti_stacked_png_1.default }, void 0);
}
exports.default = SRTILogo;
// # sourceMappingURL=SRTILogo.js.map