
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");

const CardContainer = styled_components_1.default.div `
  width: 100%;
  min-height: 180px;
  height: 100%;
  background-color: ${(props) => props.theme.backgroundColor};
  border-radius: 20px;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  align-items: center;
  justify-content: center;
`;
function LogoCard({ children }) {
    return (0, jsx_runtime_1.jsx)(CardContainer, { children }, void 0);
}
exports.default = LogoCard;
// # sourceMappingURL=logoCard.js.map