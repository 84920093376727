import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

function Manifest() {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
          }
        }
        file(ext: { eq: ".png" }, name: { eq: "icon" }) {
          publicURL
        }
      }
    `
  );

  return (
    <Helmet>
      <title>{data.site.siteMetadata.title}</title>
      <link rel="icon" href={data.file.publicURL} />
    </Helmet>
  );
}

export default Manifest;
