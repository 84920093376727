
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const logoCard_1 = require("../logoCard");

const ImagedDiv = styled_components_1.default.div `
  width: 80%;
  margin: 5%;
  height: inherit;
  background-image: ${(props) => `url(${props.imageSrc})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
function LogoContainer({ imageSrc }) {
    return ((0, jsx_runtime_1.jsx)(logoCard_1.default, { children: (0, jsx_runtime_1.jsx)(ImagedDiv, { imageSrc }, void 0) }, void 0));
}
exports.default = LogoContainer;
// # sourceMappingURL=logoContainer.js.map