
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const poly_heart_1_png_1 = require("../images/poly-heart-1.png");

function HeartBackground() {
    return ((0, jsx_runtime_1.jsx)("img", { src: poly_heart_1_png_1.default, alt: "heart", style: {
            // paddingLeft: "10%",
            // paddingTop: "60%",
            width: "80%",
            opacity: 0.1,
        } }, void 0));
}
exports.default = HeartBackground;
// # sourceMappingURL=heartBackground.js.map