
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const optumLogo_1 = require("./optumLogo");
const SRTILogo_1 = require("./SRTILogo");
const linkage_1 = require("../linkage");

const LogoGroupInnerContainer = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
`;
const LogoCardContainer = styled_components_1.default.div `
  width: 80%;
  height: fit-content;
`;
const CenterGapContainer = styled_components_1.default.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 200%;
`;
function LogoGroup() {
    return ((0, jsx_runtime_1.jsxs)(LogoGroupInnerContainer, { children: [(0, jsx_runtime_1.jsx)(LogoCardContainer, { children: (0, jsx_runtime_1.jsx)(optumLogo_1.default, {}, void 0) }, void 0), (0, jsx_runtime_1.jsxs)(CenterGapContainer, { children: [(0, jsx_runtime_1.jsx)(linkage_1.default, {}, void 0), (0, jsx_runtime_1.jsx)(linkage_1.default, { scaleFactor: -1 }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(LogoCardContainer, { children: (0, jsx_runtime_1.jsx)(SRTILogo_1.default, {}, void 0) }, void 0)] }, void 0));
}
exports.default = LogoGroup;
// # sourceMappingURL=logoGroup.js.map